<template>
    <div>
        <st-modal
            id="st-modal-decision-purpose"
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            :visible="visible"
            size="md"
            @hide="hide"
            @modalClosed="hide"
            :title="headerText"
            customClass="form-modal"
        >
            <template #body>
                <form class="form"
                      novalidate="novalidate"
                      id="st_modal_decision_purpose_form"
                >
                    <div class="row pb-6">
                        <div class="col">
                            <div class="form-group">
                                <label>
                                    {{ fields.name.label }} *
                                </label>
                                <st-input-text
                                    ref="name"
                                    name="name"
                                    v-model="model[fields.name.name]"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <st-button
                            size="large"
                            variant="link"
                            :callback="hide"
                        > {{ $t('GENERAL.BUTTON.CANCEL') }}
                        </st-button>
                        <st-button
                            size="large"
                            variant="primary"
                            :callback="doSubmit"
                        >
                            <span>{{ $t('GENERAL.BUTTON.SAVE') }}</span>
                        </st-button>
                    </div>
                </form>
            </template>
        </st-modal>
    </div>
</template>

<script>

import {FormSchema} from '@/shared/form/form-schema';
import {createFormValidation} from '@/shared/utils/create-form-validation';
import {DecisionPurposeModel} from '@/modules/decision-purpose/decision-purpose-model';

const {fields} = DecisionPurposeModel;

const formSchema = new FormSchema([
    fields.id,
    fields.name,
]);

import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'DecisionPurposeFormModal',
    props: {
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: false,
            rules: formSchema.rules(),
            model: null
        };
    },
    computed: {
        ...mapGetters({
            record: 'decisionPurpose/form/record',
            filters: 'decisionPurpose/list/filters',
        }),
        headerText() {
            return this.editMode ?
                this.$t('DECISION_PURPOSE.FORM.EDIT_TITLE') :
                this.$t('DECISION_PURPOSE.FORM.ADD_TITLE');
        },
        fields() {
            return fields;
        }
    },
    methods: {
        ...mapActions({
            doCreate: 'decisionPurpose/form/create',
            doUpdate: 'decisionPurpose/form/update',
            doFetch: 'decisionPurpose/list/doFetch',
        }),
        hide() {
            this.$emit('close');
            this.doReset();
        },
        doReset() {
            this.model = formSchema.initialValues(this.record);
        },
        open() {
            this.visible = true;
        },
        async doSubmit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const {id, ...values} = formSchema.cast(this.model);
                if (this.editMode) {
                    this.doUpdate({id, values}).then(() => this.hide());
                } else {
                    this.doCreate(values).then(() => {
                        this.hide();
                        this.doFetch(this.filters);
                    });
                }
            }
        }
    },
    async created() {
        this.model = formSchema.initialValues(this.record);
    },
    mounted() {
        // Initialize the form after the modal is shown
        this.$root.$on('bv::modal::shown', (modalObj) => {
            if (modalObj.componentId === 'st-modal-decision-purpose') {
                this.fv = createFormValidation('st_modal_decision_purpose_form', this.rules);
            }
        });
    }
};
</script>

