import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';

const label = (name) => i18n.t(`DECISION_PURPOSE.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`DECISION_PURPOSE.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    name: new StringField('name', label('name'), { required: true }, {
        placeholder: placeholder('name'),
    }), 
};

export class DecisionPurposeModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
